import { Box, Checkbox as CheckboxUI } from '@material-ui/core';
import React from 'react';
import { CheckboxContainer, styles } from './styles';

const Checkbox = ({
  children,
  className,
  href,
  size,
  disable,
  isChecked,
  ...props
}) => {
  const [checked, setChecked] = React.useState(isChecked);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  return (
    <CheckboxContainer>
      <CheckboxUI
        color='primary'
        checked={checked}
        href={href}
        size={size}
        className={`${styles().checkbox} ${className}`}
        disabled={disable}
        onChange={handleChange}
        {...props}
      />
      <Box width='100%'>{children}</Box>
    </CheckboxContainer>
  );
};

export default Checkbox;
