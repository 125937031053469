import { Avatar, Typography } from '@material-ui/core';
import React from 'react';
import { CommentsContainer, IdentificationContainer, styles } from './styles';

const Comments = ({ className, avatar, name, date, comment, ...props }) => {
  return (
    <CommentsContainer className={className} {...props}>
      <IdentificationContainer>
        <Avatar className='avatar' alt='avatar' src={avatar} />
        <div className='identificationContent'>
          <Typography className={styles().name} gutterBottom component='h2'>
            {name}
          </Typography>
          <Typography className={styles().date} gutterBottom component='p'>
            {date}
          </Typography>
          <Typography className={styles().comment} gutterBottom component='p'>
            {comment}
          </Typography>
        </div>
      </IdentificationContainer>
    </CommentsContainer>
  );
};

export default Comments;
