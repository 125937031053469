import { makeStyles } from '@material-ui/core';

export const styles = makeStyles({
  card: {
    maxWidth: '100%',
    borderRadius: '15px !important',

    '@media (min-width: 481px)': {
      borderRadius: '15px !important',
    },
  },
});
