import { Card as CardUI } from '@material-ui/core';
import React from 'react';
import { styles } from './styles';

const Card = ({ children, className, ...props }) => {
  return (
    <CardUI className={`${styles().card} ${className}`} {...props}>
      {children}
    </CardUI>
  );
};

export default Card;
