import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { Card } from '../../atoms';

export const styles = makeStyles({
  title: {
    width: '100%',
    textAlign: 'left',
    alignSelf: 'center',
    color: '#fff',
  },
});

export const FollowCardContainer = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  background-color: #679b9b;

  .avatarContent {
    display: flex;
  }

  .avatar {
    margin-right: 10px;
  }
`;
