import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const styles = makeStyles({
  productName: {
    color: '#7C7A77',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '22.5px',
  },

  productPrice: {
    color: '#000',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '22.5px',
    marginBottom: '40px',
  },

  productDescriptionTitle: {
    color: '#7C7A77',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '22.5px',
    marginBottom: '16px',
  },

  productDescriptionText: {
    color: '#7C7A77',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '22.5px',
  },
});

export const ProductDetailsContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StoreContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
