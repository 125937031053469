import { CardContent, CardMedia, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import polishop from '../../../assets/img/polishop.png';
import { Button, Card, Icon } from '../../atoms';
import { Carousel, Input } from '..';
import {
  ButtonsContainer,
  PlayerContainer,
  StoreContainer,
  styles,
} from './styles';

const ProductCard = ({ className, name, price, ...props }) => {
  const [favorited, setFavorite] = useState(false);

  const listCarousel = [
    {
      component: (
        <PlayerContainer>
          <CardMedia
            component='img'
            alt='Contemplative Reptile'
            image={polishop}
            title='Contemplative Reptile'
            className={styles().cardMedia}
          />
        </PlayerContainer>
      ),
    },
    {
      component: (
        <CardMedia
          component='img'
          alt='Contemplative Reptile'
          height='inherit'
          image={polishop}
          title='Contemplative Reptile'
          className={styles().cardMedia}
        />
      ),
    },
  ];

  return (
    <Card className={className} {...props}>
      <Carousel slides={listCarousel} />
      <CardContent className={styles().cardContent}>
        <StoreContainer>
          <Typography
            className={styles().productName}
            gutterBottom
            component='h2'
          >
            {name}
          </Typography>
          <Icon
            onClick={() => setFavorite(!favorited)}
            name={favorited ? 'favorited' : 'unfavorite'}
            size={28}
            color={favorited ? '#daa520' : '#7C7A77'}
          />
        </StoreContainer>
        <Typography
          className={styles().productPrice}
          gutterBottom
          component='h2'
        >
          R$ {price}
        </Typography>
        <ButtonsContainer>
          <Input
            borderRadius='8px'
            defaultValue={1}
            widht='30px'
            fullWidth={false}
            backgroundColor='#F2F2F2'
            id='email'
            className={styles().inputQtd}
          />
          <Button className={styles().button} size='large'>
            comprar
          </Button>
        </ButtonsContainer>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
