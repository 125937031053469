import { Typography } from '@material-ui/core';
import React from 'react';
import { Button } from '../../atoms';
import Comments from '../Comments';
import Input from '../Input';
import {
  CommentBar,
  CommentBarContainer,
  CommentsListContainer,
  styles,
} from './styles';

const CommentsList = ({ className, comments, ...props }) => {
  return (
    <CommentsListContainer className={className} {...props}>
      <Typography className={styles().comments} gutterBottom component='h2'>
        Comentários
      </Typography>
      {!!comments.length &&
        comments.map(item => (
          <Comments
            key={item.id}
            avatar={item.avatar}
            name={item.name}
            date={item.date}
            comment={item.comment}
          />
        ))}
      {!comments.length && (
        <Typography className={styles().text} gutterBottom component='h2'>
          Seja o primeiro a comentar
        </Typography>
      )}
      <CommentBarContainer>
        <Input
          className={styles().inputComment}
          label='Deixe um comentário ...'
        />
        <Button size='large' className={styles().sendButton}>
          enviar
        </Button>
      </CommentBarContainer>
    </CommentsListContainer>
  );
};

export default CommentsList;
