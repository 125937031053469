import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { lazy } from '@loadable/component';
import { Loader } from '../components/molecules';

const HomeModule = lazy(() => import(/* webpackChunkName: "home" */ './Home'));
const HomeOldModule = lazy(() =>
  import(/* webpackChunkName: "home" */ './HomeOld'),
);
const LoginModule = lazy(() =>
  import(/* webpackChunkName: "login" */ './Login'),
);
const RegisterModule = lazy(() =>
  import(/* webpackChunkName: "register" */ './Register'),
);
const ForgotPasswordModule = lazy(() =>
  import(/* webpackChunkName: "forgot-password" */ './ForgotPassword'),
);
const LiveModule = lazy(() => import(/* webpackChunkName: "live" */ './Live'));

const RootRouter = () => (
  <Suspense fallback={<Loader />}>
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<HomeModule />} />;
        <Route path='/home' element={<HomeOldModule />} />;
        <Route path='/login/*' element={<LoginModule />} />;
        <Route path='/register/*' element={<RegisterModule />} />;
        <Route path='/forgot-password/*' element={<ForgotPasswordModule />} />;
        <Route path='/live/*' element={<LiveModule />} />;
      </Routes>
    </BrowserRouter>
  </Suspense>
);

export default RootRouter;
