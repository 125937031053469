import { Button as ButtonUI } from '@material-ui/core';
import React from 'react';
import { styles } from './styles';

const Button = ({
  children,
  className,
  href,
  size,
  disabled,
  variant,
  onClick,
  ...props
}) => {
  return (
    <ButtonUI
      href={href}
      size={size}
      className={`${styles().button} ${styles()[variant]} ${className}`}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </ButtonUI>
  );
};

Button.defaultProps = {
  variant: 'primary',
};

export default Button;
