import useEmblaCarousel from 'embla-carousel-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Icon } from '../../atoms';
import * as Styled from './styles';

const PrevButton = ({ enabled, onClick }) => (
  <button
    className='embla__button embla__button--prev'
    onClick={onClick}
    disabled={!enabled}
  >
    <Icon name='arrow-left' disabled={!enabled} size={32} />
  </button>
);

const NextButton = ({ enabled, onClick }) => (
  <button
    className='embla__button embla__button--next'
    onClick={onClick}
    disabled={!enabled}
  >
    <Icon name='arrow-right' disabled={!enabled} size={32} />
  </button>
);

const Carousel = ({ slides, options, className }) => {
  const [viewportRef, embla] = useEmblaCarousel(
    options || {
      align: 'start',
      skipSnaps: true,
      startIndex: 0,
    },
  );
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on('select', onSelect);
    onSelect();
  }, [embla, onSelect]);

  return (
    <Styled.SliderContainer className={`embla ${className}`}>
      <div className='embla__viewport' ref={viewportRef}>
        <div className='embla__container'>
          {slides.map((item, index) => (
            <div className='embla__slide' key={index}>
              <div className='embla__slide__inner'>{item.component}</div>
            </div>
          ))}
        </div>
      </div>
      <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
      <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
    </Styled.SliderContainer>
  );
};

export default Carousel;
