import { makeStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles';

export const styles = makeStyles({
  button: {
    marginTop: '5px',
    marginBottom: '5px',
    textTransform: 'none',
    borderRadius: '8px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },

  primary: {
    backgroundColor: '#FF914D',
    color: '#FFF',

    '&:hover': {
      backgroundColor: darken('#FF914D', 0.1),
    },
  },

  primaryInverted: {
    backgroundColor: '#fff',
    border: '1px solid #FF914D',
    color: '#FF914D',

    '&:hover': {
      backgroundColor: darken('#fff', 0.1),
    },
  },

  secundary: {
    backgroundColor: 'inherit',
    color: '#FF914D',

    '&:hover': {
      backgroundColor: darken('#FFF', 0.1),
    },
  },
});
