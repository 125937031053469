import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { Center } from './styles';

const Loader = ({ className }) => {
  return (
    <Center className={className}>
      <CircularProgress size={24} style={{ color: '#FF914D' }} />
      <Typography>Carregando</Typography>
    </Center>
  );
};

export default Loader;
