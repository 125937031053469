import { StylesProvider } from '@material-ui/styles';
import React from 'react';
import RootRouter from './routers';
import GlobalStyle from './styles/global';
import './assets/fonts/fonts.css';
import './assets/fonts/IconsVendi.css';

function App() {
  return (
    <StylesProvider injectFirst>
      <GlobalStyle />
      <RootRouter />
    </StylesProvider>
  );
}

export default App;
