import { darken, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const styles = makeStyles({
  cardMedia: {
    borderRadius: '15px 15px 0 0',
    height: 'inherit',
  },

  cardContent: {
    padding: '20px !important',
  },

  title: {
    color: '#000',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '27.5px',
  },

  productName: {
    color: '#7C7A77',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '22.5px',
  },

  productPrice: {
    color: '#000',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '22.5px',
  },

  inputQtd: {
    margin: '0 !important',
  },

  button: {
    margin: 0,
  },

  follow: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: '#7C7A77',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17.5px',
    marginLeft: '10px',

    '&:hover': {
      color: darken('#7C7A77', 0.1),
      textDecoration: 'none',
    },
  },
});

export const StoreContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const ButtonsContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  grid-gap: 10px;
  margin-top: 16px;
`;

export const IconsContent = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
`;

export const PlayerContainer = styled('div')`
  width: 100%;
  height: inherit;
`;
