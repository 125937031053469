import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Icon } from '../../atoms';
import { ProductDetailsContainer, StoreContainer, styles } from './styles';

const ProductDetails = ({ className, name, price, description, ...props }) => {
  const [favorited, setFavorite] = useState(false);

  return (
    <ProductDetailsContainer className={className} {...props}>
      <StoreContainer>
        <Typography
          className={styles().productName}
          gutterBottom
          component='h2'
        >
          {name}
        </Typography>
        <Icon
          onClick={() => setFavorite(!favorited)}
          name={favorited ? 'favorited' : 'unfavorite'}
          size={28}
          color={favorited ? '#daa520' : '#7C7A77'}
        />
      </StoreContainer>
      <Typography className={styles().productPrice} gutterBottom component='h2'>
        R$ {price}
      </Typography>
      <Typography
        className={styles().productDescriptionTitle}
        gutterBottom
        component='h3'
      >
        Descrição do produto
      </Typography>
      <Typography
        className={styles().productDescriptionText}
        gutterBottom
        component='p'
      >
        {description}
      </Typography>
    </ProductDetailsContainer>
  );
};

export default ProductDetails;
