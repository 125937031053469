import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const styles = makeStyles({
  comments: {
    color: '#7C7A77',
    fontWeight: 500,
    fontSize: '28px',
    lineHeight: '22.5px',
    marginBottom: 25,
  },

  text: {
    color: '#7C7A77',
    fontWeight: 500,
    fontSize: '28px',
    lineHeight: '22.5px',
  },

  inputComment: {
    margin: 0,
  },

  sendButton: {
    margin: 0,
  },
});

export const CommentsListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CommentBarContainer = styled('div')`
  display: flex;
  gap: 16px;
  margin-bottom: 40px;
`;
