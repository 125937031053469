import React from 'react';
import { styles, TextFieldUI } from './styles';

const Input = ({
  className,
  fullWidth,
  widht,
  disabled,
  borderRadius,
  backgroundColor,
  ...props
}) => {
  return (
    <>
      <TextFieldUI
        borderradius={borderRadius}
        widht={widht}
        backgroundcolor={backgroundColor}
        disabled={disabled}
        fullWidth={fullWidth}
        variant='outlined'
        className={`${styles().textField} ${className}`}
        {...props}
      />
    </>
  );
};

Input.defaultProps = {
  backgroundColor: '#F2F2F2',
  fullWidth: true,
  widht: '100%',
  borderRadius: '15px',
};

export default Input;
