import { Avatar, Typography } from '@material-ui/core';
import React from 'react';
import { Button } from '../../atoms';
import { FollowCardContainer, styles } from './styles';
import polishopImage from '../../../assets/img/polishop.png';

const FollowCard = ({ className, name }) => {
  return (
    <FollowCardContainer className={className}>
      <div className='avatarContent'>
        <Avatar className='avatar' alt='avatar' src={polishopImage} />
        <Typography className={styles().title} variant='h6' component='h2'>
          {name}
        </Typography>
      </div>
      <div>
        <Button
          size='large'
          variant='primaryInverted'
          className={styles().enterButton}
        >
          seguir
        </Button>
      </div>
    </FollowCardContainer>
  );
};

export default FollowCard;
