import React from 'react';
import { IconContainer } from './styles';

const Icon = ({ className, name, color, size, flex, href, onClick }) => {
  return (
    <IconContainer
      href={href}
      onClick={onClick}
      color={color}
      size={size}
      flex={flex}
      className={`icon-${name} ${className}`}
    />
  );
};

Icon.defaultProps = {
  color: '#000000',
  size: 16,
};

export default Icon;
