import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const styles = makeStyles({
  name: {
    color: '#7C7A77',
    fontWeight: 500,
    fontSize: '28px',
    lineHeight: '22.5px',
    marginBottom: 0,
  },

  date: {
    color: '#7C7A77',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22.5px',
    marginBottom: 20,
  },

  comment: {
    color: '#000',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22.5px',
  },
});

export const CommentsContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const IdentificationContainer = styled('div')`
  display: flex;
  margin-bottom: 20px;

  .identificationContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 20px;
  }
`;
