import { makeStyles, styled, TextField } from '@material-ui/core';

export const styles = makeStyles({
  textField: {
    margin: '10px 0',
  },
});

export const TextFieldUI = styled(TextField)({
  '& .Mui-focused': {
    color: '#767373',
  },
  '& label.Mui-focused': {
    color: '#767373',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiInputBase-input': {
    width: props => props.widht,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: props => props.backgroundcolor,
    borderRadius: props => props.borderradius,

    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});
